<template>
  <div>
    <b-card no-body class="mb-2">
      <div class="m-2">
        <div class="form-row justify-content-between">
          <div class="col-md-2">
            <v-select
              label="title"
              required
              v-model="filters.perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </div>
          <div class="col-md-4 offset-md-5">
            <input
              type="search"
              class="form-control"
              placeholder="Pesquisar..."
              v-model="filters.title"
            />
          </div>

          <div class="col-md-1">
            <button
              @click="search"
              style="padding: 14px !important"
              type="button"
              class="btn btn-primary btn-block"
            >
              <i v-if="!btnSearch" class="bi bi-search"></i>
              <b-spinner v-else small variant="light" />
            </button>
          </div>
        </div>
      </div>
    </b-card>

    <b-card no-body class="mb-0">
      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-filtered-text="Nenhum registro encontrado"
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(name)="data">
          <b-icon
            v-b-tooltip.hover.top="data.item.status ? 'Ativo' : 'Inativo'"
            style="width: 9px"
            icon="circle-fill"
            :class="data.item.status ? 'text-success' : 'text-danger'"
            :id="`product-row-${data.item.id}-status-icon`"
          />
          {{ data.item.name }}
        </template>

        <template #cell(created_at)="{ item }">
          <span v-if="!item.updated_at">
            {{ item.created_at | datePT(true) }}
          </span>
          <span v-else>
            {{ item.updated_at | datePT(true) }}
          </span>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              v-if="$can('Curso Venda - Excluir', 'Pedagógico')"
              @click="confirmDelete(data.item.uuid)"
            />
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
              v-if="$can('Curso Venda - Editar', 'Pedagógico')"
              @click="modalEdit(data.item.uuid)"
            />
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rowsTable="rowsTable"
        :currentPage="filters.currentPage"
        :perPage="filters.perPage"
        :totalRows="totalRows"
      />

      <b-modal
        id="modal-create-course-sale"
        modal-class="modal-create"
        :title="titleModal"
        centered
        hide-footer
        no-close-on-backdrop
      >
        <b-form @submit.prevent="submitCreate">
          <div class="form-row">
            <div class="col-md-12 col-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Nome
              </label>
              <div class="form-group">
                <b-form-input
                  v-model="item.name"
                  :class="{ 'is-invalid': $v.item.name.$error }"
                />
              </div>
            </div>
            <div class="col-md-5 col-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Tipo
              </label>
              <div class="form-group">
                <v-select
                  label="title"
                  :searchable="false"
                  item-text="title"
                  item-value="code"
                  placeholder="Tipo"
                  v-model="item.type"
                  :options="optionsCourseSaleTypes"
                  :class="{ 'is-invalid': $v.item.type.$error }"
                />
              </div>
            </div>
            <div class="col-md-4 col-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Status
              </label>
              <div class="form-group">
                <v-select
                  label="title"
                  :searchable="false"
                  item-text="title"
                  item-value="code"
                  placeholder="Status"
                  v-model="item.status"
                  :options="optionsStatus"
                  :class="{ 'is-invalid': $v.item.status.$error }"
                />
              </div>
            </div>
          </div>
          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-7 col-12">
              <button
                type="button"
                @click="$bvModal.hide('modal-create-course-sale')"
                class="btn btn-light float-left"
              >
                Fechar
              </button>
              <ButtonsActionsFooter
                variant="success"
                :submited="submited"
                :text="textSubmit"
                subtext="Aguarde..."
              />
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
  VBTooltip,
  BModal,
  BFormGroup,
  BTooltip,
  BForm,
  BCardText,
  BFormRow,
  BContainer,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required } from "vuelidate/lib/validators";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";

export default {
  components: {
    BCard,
    CustomPaginateTable,
    BModal,
    BFormGroup,
    BRow,
    BSpinner,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    vSelect,
    BTooltip,
    VBTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    ButtonsActionsFooter,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  data() {
    return {
      filters: {
        currentPage: 1,
        perPage: 100,
        title: '',
      },
      btnSearch: false,
      uuid: null,
      textSubmit: "",
      submited: false,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      tableColumns: [
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "type_name",
          label: "Tipo",
          tdClass: "text-center",
          thClass: "text-center",
          thStyle: "width: 180px",
        },
        {
          key: "created_at",
          label: "Cadastrado",
          sortable: false,
          class: "text-center",
          thStyle: "width: 210px",
        },
        {
          key: "actions",
          label: "",
          thClass: "text-center td-actions",
          tdClass: "text-center",
          thStyle: "width: 80px",
        },
      ],
      dataItems: [],
      titleModal: "",
      item: {
        name: "",
        type: "",
        status: "",
      },
      optionsCourseSaleTypes: [],
      optionsStatus: [
        {
          title: "Ativo",
          code: 1,
        },
        {
          title: "Inativo",
          code: 0,
        },
      ],
    };
  },
  validations: {
    item: {
      name: {
        required,
      },
      type: {
        required,
      },
      status: {
        required,
      },
    },
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  async mounted() {
    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });
    
    this.optionsCourseSaleTypes = await this.$store.dispatch(
      "CourseSale/forSelectTypes"
    );

    this.getData();
    this.$root.$on("open:modal-create-course-sale", () => {
      this.clearForm();
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar";
      this.$bvModal.show("modal-create-course-sale");
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-course-sal");
  },
  methods: {
    search() {
      this.btnSearch = true;
      this.getData();
    },
    modalEdit(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar";
      this.clearForm();
      this.$store.dispatch("CourseSale/show", uuid).then((data) => {
        this.item = data.data;
        this.uuid = this.item.uuid;
        this.$bvModal.show("modal-create-course-sale");
      });
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        const data = {
          name: this.item.name,
          status: this.item.status.code,
          type_uuid: this.item.type.code,
        };

        if (this.textSubmit === "Cadastrar") {
          this.$store
            .dispatch("CourseSale/create", data)
            .then(() => {
              this.notifyDefault("success");
              this.getData();
              this.$v.$reset();
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-course-sale");
                this.clearForm();
              });
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("CourseSale/update", { uuid: this.uuid, data: data })
            .then(() => {
              this.getData();
              this.$v.$reset();

              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-course-sale");
                this.clearForm();
              });
            })
            .catch(() => {
              this.submited = false;
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    clearForm() {
      this.item = {
        name: "",
        active: false,
      };
    },
    async getData() {
      this.$store
        .dispatch("CourseSale/all", this.filters)
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.currentPage = data.meta.current_page;
          }
        })
        .finally(() => {
          this.btnSearch = false;
        });
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("CourseSale/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
};
</script>
